.drag_mark_layer {
  position: fixed;
  top: 0;
  // 有部分层级会挡住拖拽框，所以这里设置一个很大的层级
  z-index: 9999999;
  background: rgba(21, 33, 52, 0.3);
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #fff;
  display: none;
  padding: 100px;
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px dashed #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    .content {
      pointer-events: none;
      text-align: center;
      img {
        height: 60px;
        pointer-events: none;
      }
      p {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        line-height: 74px;
        margin: 0;
        margin-top: 24px;
        pointer-events: none;
      }
    }
    .angle:nth-child(2) {
      pointer-events: none;
      position: absolute;
      left: -3px;
      top: -3px;
      padding: 48px;
      border-style: solid;
      border-color: white;
      border-width: 5px 0 0 5px;
      border-radius: 6px 0 0 0;
    }
    .angle:nth-child(3) {
      pointer-events: none;
      position: absolute;
      right: -3px;
      top: -3px;
      padding: 48px;
      border-style: solid;
      border-color: white;
      border-width: 5px 5px 0 0;
      border-radius: 0 6px 0 0;
    }
    .angle:nth-child(4) {
      pointer-events: none;
      position: absolute;
      right: -3px;
      bottom: -3px;
      padding: 48px;
      border-style: solid;
      border-color: white;
      border-width: 0 5px 5px 0;
      border-radius: 0 0 6px 0;
    }
    .angle:nth-child(5) {
      pointer-events: none;
      position: absolute;
      left: -3px;
      bottom: -3px;
      padding: 48px;
      border-style: solid;
      border-color: white;
      border-width: 0 0 5px 5px;
      border-radius: 0 0 0 6px;
    }
  }
}
.show {
  display: block;
}
