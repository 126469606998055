@import "src/common";

.ft_btn {
  height: 48px;
  // box-shadow: 0px 4px 4px 1px rgba(44, 125, 250, 0.1);
  border-radius: 4px;
  background: #f2f2f3;
  color: #2b2b2b;
  border: 1px solid transparent;
  font-family: $base-font-family;
  cursor: pointer;
  outline: none;

  &:hover {
    background: #eaebec;
  }

  &:active {
    background: #eaebec;
    color: #9da2ad;
    svg:not(.no_icon) {
      path {
        fill: #9da2ad;
      }
    }
  }

  &:disabled {
    background: #eaebec;
    color: #c7cddb;
    svg:not(.no_icon) {
      path {
        fill: #c7cddb;
      }
    }
  }
}

.primary {
  background: #2643ff;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;

  &:hover {
    background: #223ce5;
    svg:not(.no_icon) {
      path {
        fill: #ffffff;
      }
    }
  }

  &:active {
    background: #5477f0;
    color: #fff;
    svg:not(.no_icon) {
      path {
        fill: #fff;
      }
    }
  }

  &:disabled {
    background: #5477f0;
    color: #fff;
    svg:not(.no_icon) {
      path {
        fill: #fff;
      }
    }
  }
}
