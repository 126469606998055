.mySwiper {
  width: 100%;
  padding-bottom: 42.7%;
  height: 0px;
  overflow: hidden;
  .swiper {
    width: 100%;
  }

  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .swiper-pagination {
    height: 12px;
    position: absolute;
    bottom: 6px;
  }

  .bullet-default {
    border-radius: 5px;
    display: inline-block;
    width: 10px;
    height: 10px;
    background: rgba(255, 255, 255, 0.3);
    &:first-child {
      margin-right: 5px;
    }
    &:nth-child(2) {
      margin-right: 5px;
    }
  }
  .bullet-active {
    display: inline-block;
    border-radius: 5px;
    width: 10px;
    height: 10px;
    background: rgba(255, 255, 255, 0.8);
  }
}
