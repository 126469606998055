@import "src/styles/variables.scss";

.goart_content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.goart_ad {
  height: 100%;
  background: #000000;
  position: relative;
  flex: 0 0 305px;

  &_btn {
    position: fixed;
    z-index: 99999;
    right: 20px;
    bottom: 30px;
    border-radius: 20px;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    border: 1px solid #fff;
    background: #333335;
    padding: 6px 10px;
    opacity: 0.6;
    cursor: pointer;

    svg {
      vertical-align: middle;
      margin-right: 8px;
    }

    &:hover {
      opacity: 1;
    }
  }

  #uniconsent-config {
    position: fixed;
    z-index: 10;
    right: 20px;
    bottom: 10px;
    display: flex;
    justify-content: flex-end;

    a {
      display: inline-block;
      border-radius: 20px;
      font-size: 12px;
      opacity: 0.3;
      color: #ffffff;
      transform: scale(0.8);
      transform-origin: right;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.layout {
  flex: 1;
  z-index: 1;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  .websiteHeader_layout {
    position: relative;
  }

  &-content-mobile {
    flex-direction: column;

    .layout-content_left {
      height: 186px;
      width: 100%;
    }

    .layout-content_canvas {
      max-height: calc(100% - 180px);
    }
  }

  &-content-noimage {
    .layout-content_canvas {
      max-height: 100% !important;
    }
  }

  &-content {
    position: relative;
    flex: 1;
    width: 100%;
    // max-height: calc(100% - 64px);
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    &_canvas {
      flex: 1;
      background: $bg_content;

      @media screen and (max-width: 960px) {
        overflow: hidden;
      }
    }

    &-loading {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  &_effect_loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    z-index: 4000;

    &::before {
      content: "";
      z-index: 999;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(5px);
      background: rgba(#000000, 0.4);
    }
  }
}

.goart_content-root_fra {
  position: absolute;
  left: 0;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(22, 23, 25, 0.6);

  backdrop-filter: blur(10px);
  align-items: center;
  justify-content: center;

  .goart_content-root_fra_inner {
    width: 90vw;
    max-width: 428px;
    border-radius: 12px;
    position: relative;
    padding: 42px;
    background: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

    .goart_content-root_fra_inner_titles {
      color: #2b2b2b;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
    }

    .goart_content-root_fra_inner_second_titles {
      color: #2b2b2b;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      text-align: center;
    }

    #goart_content-root_fra {
      margin-top: 32px;
      display: flex;
      justify-content: center;
    }

    .goart_content-root_fra_inner_close {
      position: absolute;
      right: calc(-8px - 36px);
      top: 0;
      width: 36px;
      height: 36px;
      background: rgba(255, 255, 255, 0.1);
      cursor: pointer;
      backdrop-filter: blur(10px);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #ffffff;
        width: 12px;
        height: 12px;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

@media (max-width: 750px) {
  .goart_content-root_fra_inner {
    padding: 32px 16px !important;
  }

  .goart_content-root_fra_inner_close {
    right: 0 !important;
    background: transparent !important;
    backdrop-filter: unset !important;

    svg {
      fill: #2b2b2b !important;
    }
  }

  #goart_content-root_fra {
    margin-top: 16px !important;
  }

  .goart_content-root_fra_inner_close {
    width: 32px !important;
    height: 32px !important;
    border-radius: 12px !important;
  }

  .goart_content-root_fra_inner_titles {
    width: calc(100% - 64px);
    margin: 0 auto;
  }
}

@keyframes image-loading-animation {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.loading-skeleton-base {
  background: linear-gradient(90deg, #f4f5f6 25%, rgba(244, 245, 246, 0) 37%, #f4f5f6 63%);
  background-size: 400% 100%;
  animation: image-loading-animation 1s ease infinite;
}

.loading-skeleton {
  // background: linear-gradient(90deg, #333438 25%, #3b3c41 37%, #333438 63%);
  // background: linear-gradient(90deg, #dfe5eb 25%, #f4f5f6 37%, #dfe5eb 63%);
  background: linear-gradient(90deg, #f4f5f6 25%, rgba(244, 245, 246, 0) 37%, #f4f5f6 63%);
  background-size: 400% 100%;
  animation: image-loading-animation 1s ease infinite;
  height: 100%;
  width: 100%;
}

.loading-skeleton-back {
  background: linear-gradient(90deg, rgba(74, 77, 59, 0.8) 25%, rgba(74, 77, 59, 0.3) 37%, rgba(74, 77, 59, 0.8) 63%);
  background-size: 400% 100%;
  animation: image-loading-animation 1s ease infinite;
  height: 100%;
  width: 100%;
}

.loading-skeleton-back2 {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.5) 37%, rgba(0, 0, 0, 0) 63%);
  background-size: 400% 100%;
  animation: image-loading-animation 1s ease infinite;
  height: 100%;
  width: 100%;
}

// 紫色
.loading-skeleton-purple {
  background: linear-gradient(90deg, #b4b2ff 25%, #e1e0ff 37%, #b4b3ff 63%);
  background-size: 400% 100%;
  animation: image-loading-animation 1s ease infinite;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 750px) {

  html,
  body {
    overflow: hidden;
    height: 100%;
  }
}