.download_mobile {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  &_select {
    position: absolute;
    width: 100%;
    height: 210px;
    bottom: 0;
    right: 0;
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 48px 40px;

    @media screen and (max-width: 375px) {
      padding: 48px 16px !important;
    }

    &_small,
    &_larger {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      padding: 13px 16px;
      font-size: 13px;
      font-weight: 500;
      line-height: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      box-shadow: none;
      overflow: hidden;

      span {
        white-space: nowrap;
        margin-right: 16px;
      }

      > svg {
        flex-shrink: 0;
      }
    }

    &_small {
      color: #2c7dfa;
      & > span:nth-child(3) {
        color: rgba(44, 125, 250, 0.7);
      }

      &_icon {
        width: 24px;
        height: 24px;
        background: #2c7dfa;
        border-radius: 2px;
        display: flex;
        justify-content: space-around;
        align-content: center;
        align-items: center;
        // 禁止缩放
        flex-shrink: 0;
        margin-right: 8px;
        vertical-align: middle;
        svg,
        path {
          fill: #ffffff !important;
        }

        @media screen and (max-width: 374px) {
          display: none;
        }
      }

      &:hover {
        background: #f5f8ff;
        & > span:nth-child(3) {
          color: rgba(44, 125, 250, 0.7);
        }
        svg,
        path {
          fill: #ffffff !important;
        }
      }

      &:active {
        background: #f5f8ff;
        color: rgba(44, 125, 250, 0.7);

        & > span:nth-child(3) {
          color: #b5d1fd;
        }
        svg,
        path {
          fill: #ffffff !important;
        }

        div {
          background: #91bbfc !important;
        }
      }
    }
    &_larger {
      margin-top: 16px;
      color: #ffffff;
      & > span:nth-child(3) {
        color: rgba(255, 255, 255, 0.7);
      }
      &:hover {
        div svg,
        div svg path {
          fill: #2c7dfa !important;
        }
      }

      &:active {
        color: #a0c4fd;
        & > span:nth-child(3) {
          color: #83b3fc;
        }
        div {
          background-color: #a0c4fd;
          svg,
          path {
            fill: #2c7dfa !important;
          }
        }
      }
      &_icon {
        width: 24px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px;
        display: flex;
        justify-content: space-around;
        align-content: center;
        align-items: center;
        // 禁止缩放
        flex-shrink: 0;
        margin-right: 8px;
        vertical-align: middle;
        svg {
          fill: #2c7dfa;
        }

        @media screen and (max-width: 350px) {
          display: none;
        }
      }

      span {
        white-space: nowrap;
      }
    }
  }
}
