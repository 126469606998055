.upload-btn {
  display: flex;
  height: 56px;
  .upload-left {
    background: #221ff6;
    border-radius: 28px 0px 0px 28px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: #ffffff;
    height: 100%;
    padding: 0 24px;
    display: flex;
    align-items: center;
    &:hover {
      background: #223ce5;
    }
    &:active {
      background: #5477f0;
      color: #fff;
    }
    svg {
      margin-right: 8px;
    }
  }
  .upload-right {
    background: #221ff6;
    border-radius: 0px 28px 28px 0px;
    height: 100%;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-left: 1px solid #ffffff;
    &:hover {
      background: #223ce5;
    }
    &:active {
      background: #5477f0;
      color: #fff;
    }
    svg {
      transform: rotate(180deg);
    }
    &.upload-right-active {
      svg {
        transform: rotate(0);
      }
    }
  }
}
