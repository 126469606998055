.seat-fra {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  p {
    &:nth-child(2) {
      font-size: 14px;
      font-weight: 500;
      color: #bec0c8;
      margin-top: 8px;
      text-align: center;
    }
    &:nth-child(3) {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 500;
      color: #9da2ad;
      text-align: center;
    }
  }
}
