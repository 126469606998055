$color: #9da2ad;
$activeColor: #ffffff;

.toolleft_mobile {
  background: #02120e;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.07999999821186066);
  user-select: none;

  .ft_scrollbar_wrap {
    height: 28px;
    // margin-top: 16px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    .ft_scrollbar > div:first-child {
      padding: 0 !important;
    }
  }
  .toolleft_effect {
    margin: 0 9px;
    flex: 1;

    &_scroll {
      height: 100%;

      &_list {
        padding: 10px 16px 0;
        display: flex;
        justify-content: flex-start;
        max-height: 100%;
      }
    }

    > div {
      height: 100%;
    }
    &_item {
      width: 88px;
      flex-shrink: 0;
      margin-top: 0;
      cursor: pointer;
      position: relative;
      padding-right: 8px;
      color: #fff;
      word-break: break-word;

      &:last-child {
        margin-right: 16px;
      }

      &:hover {
        .toolleft_effect_item_title {
          color: #ffffff;
        }
      }
      &:active {
        .toolleft_effect_item_title {
          color: #ffffff;
        }
      }

      &_active {
        .toolleft_effect_item {
          &_title {
            color: #ffffff;
          }

          &_check {
            display: flex;
            &_wrapper {
              display: flex;
            }
          }

          &_original,
          &_effect {
            position: relative;
            img {
              width: calc(100% - 8px);
              height: calc(100% - 8px);
              margin: 4px;
            }
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              border: 2px solid #2c7dfa !important;
              border-radius: 4px;
            }
          }
        }
      }

      &_title {
        font-size: 10px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
        line-height: normal;
        display: block;
        text-align: center;
      }

      &_check {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.24);
        justify-content: flex-end;
        align-content: center;
        align-items: flex-end;
        border: 2px solid #2c7dfa;
        &_wrapper {
          width: 22px;
          height: 22px;
          border-radius: 26px 0px 0px 0px;
          background: #2c7dfa;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          display: none;
          position: relative;
          svg {
            position: absolute;
            width: 12px;
            height: 12px;
            right: 2px;
            bottom: 2px;
            // path{
            //   fill:#fff
            // }
          }
        }
      }

      &_original {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: calc(100% - 4px);
        border: 2px solid #5a5f69;
        margin-bottom: 8px;
        border-radius: 4px;

        svg {
          position: absolute;
          width: 40px;
          height: 40px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          fill: #5a5f69;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &_effect {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        margin-bottom: 8px;
        border-radius: 4px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          border-radius: 2px;
        }
      }
      &_new {
        position: absolute;
        // width: 29px;
        height: 14px;
        top: -7px;
        right: 9px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        svg + svg {
          margin-left: 4px;
        }
      }
    }

    &_title {
      flex-shrink: 0;
      width: 100%;
      height: 28px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      overflow-y: hidden;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      span {
        // color: #9da2ad !important;
        display: inline-flex;
        height: 100%;
        flex-shrink: 0;
        white-space: nowrap;
        padding: 0 16px;
        font-size: 13px;
        font-weight: 500;
        color: $color;
        line-height: 16px;
        position: relative;
        justify-content: space-around;
        align-items: center;
        align-content: center;

        &.active {
          color: $activeColor;
          // &::after {
          //   content: "";
          //   position: absolute;
          //   bottom: 1px;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   width: 32px;
          //   height: 0px;
          //   border: 1px solid $activeColor;
          //   border-radius: 2px;
          // }
        }
      }
    }
  }
}
