.effect_loading_mobile {
  width: 350px !important;
  height: fit-content !important;

  .effect_loading_img {
    // height: 150px !important;
  }
}
@keyframes progress-flash {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.effect_loading {
  background: #161619;
  // background: #fff;
  box-shadow: 0px 8px 16px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px 6px 6px 6px;
  padding: 0px;
  animation: showLoading 0.2s linear;
  z-index: 1000;
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 584px;
  min-height: 168px;
  display: flex;
  align-items: center;
  flex-direction: column;
  &_header {
    // background: #f3f6f9;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;

    font-variation-settings: "opsz" auto;
    /* 文本色/深色1级 */
    color: #2b2b2b;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    position: relative;
    flex-direction: column;
    padding: 0px 40px;
    // height: 122px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 100%;

    // img {
    //   width: 16px;
    //   height: 16px;
    //   animation: loading 1s infinite linear;
    // }
    &_desc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 12px;

      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;

      color: #bec0c8;
    }
    @media screen and (max-width: 750px) {
      padding: 0 24px;
      // height: 104px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;

      font-variation-settings: "opsz" auto;
      /* 文本色/深色1级 */
      color: #2b2b2b;
      .effect_loading_header_desc {
        span {
          font-size: 14px;
        }
      }
    }
  }
  &_img {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    height: fit-content;
    margin: 0 auto;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    width: 402px;
    padding: 0px;
    margin-top: 30px;
    img {
      width: 70%;
      aspect-ratio: 67 / 36;
    }
  }

  &_person {
    font-size: 13px;
    font-weight: 500;
    color: #5a5f69;
    line-height: 24px;
    text-align: left;
    width: 100%;
    padding: 0px 40px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100px;
    flex: auto;
    justify-content: center;
    span {
      display: block;
      text-align: right;
      font-size: 13px;
      font-weight: 400;
      color: #5a5f69;
      line-height: 24px;
      margin-top: 8px;
      width: 100%;
      text-align: right;
    }
    @media screen and (max-width: 750px) {
      margin-bottom: 0px;
      padding: 0px 24px;
      height: 120px;
    }
  }
  @media screen and (max-width: 750px) {
    min-height: unset !important;
  }
}

@keyframes showLoading {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
