.noimage_mobile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #02120e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  &_center {
    flex: 1;
    width: calc(100% - 36px);
    margin: 35px 18px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border-radius: 4px 4px 4px 4px;
    border: 2px dashed #6b6e79;
    padding: 24px 16px;

    svg {
      fill: #fff;
      width: 24px;
      height: 24px;
    }

    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      flex-direction: column;
    }
  }

  &_text {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    margin-top: 12px;
  }

  &_desc {
    font-size: 13px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 20px;
    margin-top: 12px;
  }
}
