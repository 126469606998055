@import "src/styles/variables.scss";

$common_bg: #212127;
$hover_bg: #232329;
$active_bg: #232329;

.toolleft_search {
  width: 100%;
  padding: 24px 24px 16px 24px;

  .icon_close {
    fill: #9da2ad;
    cursor: pointer;

    &:hover {
      fill: #fff;
    }
  }

  .icon_close_hide {
    display: none;
  }

  &_input {
    // margin-bottom: 16px;
    height: 40px;
    background: $common_bg;
    border-radius: 6px;
    border: 2px solid transparent;

    input {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      background: $common_bg;
      color: #fff;
      line-height: 24px;
    }

    &.ant-input-affix-wrapper-focused {
      // border: 2px solid #2C7DFA !important;
      // box-shadow: 0px 0px 8px 0px #2C7DFA;
      box-shadow: 0px 0px 4px 0px #2643ff;
    }

    &.ant-input-affix-wrapper:hover {
      background: $hover_bg;
      border: 2px solid transparent !important;
      border-right-width: 2px !important;
      input {
        background: $hover_bg;
      }
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #bec0c8;
      line-height: 24px;
      line-height: 18px;
      letter-spacing: 0.3px;
    }
  }
  &_input_focus {
    border: 2px solid transparent !important;
    box-shadow: 0px 0px 4px 0px #2643ff;
    &:hover {
      background: #232329;
    }
  }

  // &_empty {
  //   &.toolleft_search_input.ant-input-affix-wrapper-focused {
  //     border: 2px solid #2C7DFA !important;

  //     &.ant-input-affix-wrapper:hover {
  //       border-right-width: 2px !important;
  //     }
  //   }
  // }
}

.toolleft_search_popover {
  max-width: $width_tool_left - 48px;
  width: $width_tool_left - 48px;
  .ant-popover-inner {
    padding: 16px;
    background: #27282c;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
  }
  .ant-popover-inner-content {
    padding: 0px;
  }
  .ant-popover-arrow-content {
    background-color: transparent;
  }
}
