.ai_image_HD {
  width: 44px;
  height: 44px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 8px 8px;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  cursor: pointer;

  @media screen and (max-width: 750px) {
    width: 32px;
    height: 32px;
  }

  &:hover {
    svg {
      fill: rgba(90, 95, 105, 0.9);
    }
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #5a5f69;
  }

  &.active,
  &:active {
    background: #4285f4;
    svg {
      fill: #ffffff;
    }
  }
}

.ai_image_compare {
  // width: 32px;
  height: 34px;
  border-radius: 8px 8px 8px 8px;
  border: none;
  border-radius: 32px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  cursor: pointer;
  padding: 0px 12px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #ffffff;
  user-select: none;
  svg {
    width: 16px;
    height: 16px;
    fill: #fff;
    path {
      fill: #fff;
    }
  }
  @media(any-hover: hover) {
    &:hover {
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  &:active {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color:rgba(255, 255, 255, 0.5);
    svg {
      fill: rgba(255, 255, 255, 0.5);
      path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &.light {
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.2);

    svg {
      fill: #000000;
    }

    &:active {
      svg {
        fill: rgba(0, 0, 0, 0.4);
      }
    }
  }
  @media screen and (max-width: 960px) {
    padding: 0px 0px;
    width: 32px;
    height: 32px;
    backdrop-filter: blur(10px);
    background: rgba(2, 18, 14, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.2);
    span{
      display: none;
    }
  }
}

.ai_image_common {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  span {
    margin-left: 8px;
  }

  @media screen and (max-width: 1080px) {
    span {
      display: none;
    }
  }
}

.ai_image_common_operate_tip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      font-size: 14px;
      font-weight: 500;
      color: #2b2b2b;
      line-height: 21px;
      border-radius: 6px;
    }
  }
}
