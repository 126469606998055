.download_size {
  height: 32px;
  background: rgba(2, 18, 14, 0.6);
  border-radius: 6px 6px 6px 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px 12px;

  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  line-height: 15px;
  white-space: nowrap;

  &.light {
    background: transparent;
    border: none;
    font-size: 13px;
    font-weight: 500;
    color: #5a5f69;
    line-height: 16px;
  }
}
