.FTSkeleton {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  column-gap: 8px;

  .item {
    width: calc(33.3333% - 8px);

    .itemInnerBox {
      padding-top: 100%;
      // background: #09f;
      border-radius: 4px;
    }
  }
}

