@keyframes fadeup {
  0% {
    clip-path: polygon(100% 0%, 100% 100%, 100% 100%, 100% 0%);
  }
  50% {
    clip-path: polygon(100% 0%, 100% 100%, 0 100%, 0% 0%);
  }
  100% {
    clip-path: polygon(100% 0%, 100% 100%, 100% 100%, 100% 0%);
  }
}
.effect-fra {
  &:hover {
    .effect-collect {
      display: flex;
    }
    .effect-after {
      // animation-play-state: running;
      // pointer-events: auto;
      animation: fadeup 1s ease-out forwards;
    }
  }
}
.effect-collect {
  position: absolute;
  z-index: 6;
  left: 4px;
  bottom: 4px;
  background: rgba(43, 43, 43, 0.5);
  width: 24px;
  height: 24px;
  border-radius: 4px;
  backdrop-filter: blur(10px);
  display: none;
  align-items: center;
  justify-content: center;
  &.effect-active {
    left: 8px;
    bottom: 8px;
  }
  &:hover {
    background: rgba(43, 43, 43, 0.8);
  }
}

.effect-image-fra {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  .effect-after {
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    clip-path: polygon(100% 0%, 100% 100%, 100% 100%, 100% 0%);
    // animation: fadeup 2s ease-out forwards;
    // animation-play-state: paused;
    // pointer-events: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    & > div {
      height: 100%;
      aspect-ratio: 1 / 1;
    }
    img {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}
.tooltips {
  padding-bottom: 0px !important;
  max-width: 326px;
  :global {
    .ant-tooltip-arrow {
      display: none;
    }
  }
}
.regenerate {
  z-index: 5;
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 24px;
  height: 24px;
  background: rgba(43, 43, 43, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.regenerate-active {
    right: 8px;
    bottom: 8px;
  }
  &:hover {
    background: rgba(43, 43, 43, 0.8);
  }
}

.tooltips-white {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  :global {
    .ant-tooltip-inner {
      min-height: unset;
      line-height: 1;
      font-size: 12px;
      font-weight: 500;
      color: rgba(43, 43, 43, 0.8);
      background: #ffffff;
    }
    .ant-tooltip-arrow-content {
      background: #ffffff;
    }
  }
}

.items-new {
  line-height: 1;
  background: #db2358;
  padding: 2px 4px;
  border-radius: 7px;
  font-size: 9px;
  font-weight: 600;
  color: #ffffff;
  z-index: 6;
}
.items-hot {
  line-height: 1;
  background: #ed6409;
  padding: 2px 4px;
  border-radius: 7px;
  font-size: 9px;
  font-weight: 600;
  color: #ffffff;
  z-index: 6;
}

.tips-content {
  position: absolute;
  left: 50%;
  bottom: 50%;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: #ffffff;
  border-radius: 4px;
  padding: 8px;
  background: #1b1e24;
  z-index: 5;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row-reverse;
}

.tips-content {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.tips-position {
  position: fixed;
  padding: 8px;
  z-index: 10000;
  // background: #1b1e24;
  border-radius: 4px;
  // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  word-break: break-all;
  word-wrap: break-word;
  color: #ffffff;
  transform: translate(0, -100%);
  font-size: 12px;
  font-weight: normal;
  max-width: 326px;
  line-height: 18px;
  cursor: initial;
  max-height: 300px;
  overflow-y: auto;
  background: rgba(27, 30, 36, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}
