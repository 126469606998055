.upload-right {
  svg {
    transform: rotate(180deg);
  }
  &.upload-right-active {
    svg {
      transform: rotate(0);
    }
  }
}
