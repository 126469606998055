.thumb-vertical,
.thumb-horizontal {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
.track-vertical {
  right: 6px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
}
.track-horizontal {
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 3px;
}

.ft_scrollbar_wrap {
  height: calc(100%);
  position: relative;
  .go_top_btn {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    bottom: 24px;
    right: 24px;
    z-index: 100;
    text-align: center;
    svg {
      color: #fff;
      font-size: 16px;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
    &:active,
    &.active {
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.ft_scrollbar {
  > div:first-child {
    padding: 0 24px;

    &::-webkit-scrollbar {
      height: 0px;
    }
    // &::-moz-scrollbar {
    //   width: 0px;
    //   height: 0px;
    // }

    // &::-o-scrollbar {
    //   width: 0px;
    //   height: 0px;
    // }
  }
}

.ft_scrollbar {
  &.dark {
  }
  &.white {
    .thumb-vertical,
    .thumb-horizontal {
      background-color: rgba(255, 255, 255, 0.2);
      &:hover,
      &:active {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  }
  &.preview {
    .thumb-vertical,
    .thumb-horizontal {
      background-color: rgba(0, 0, 0, 0.2);
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .thumb-vertical,
  .thumb-horizontal {
    opacity: 0;
    transition: 0.2s opacity ease-in-out;
  }
  &:hover,
  &:active {
    .thumb-vertical,
    .thumb-horizontal {
      opacity: 1;
    }
  }
}
