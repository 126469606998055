.toolleft_effect_list {
  &_scroll {}

  .toolleft_mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    cursor: pointer;
    z-index: 1000;
    // height: 100%;
  }

}

