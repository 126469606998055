.effect_collapse_item {
  height: auto;
  cursor: pointer;
  position: relative;
  &_image {
    width: 100%;
    padding-bottom: 100%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      border-radius: 2px;
    }
  }

  &:hover {
    .effect_collapse_item_title {
      color: #ffffff;
    }
    .effect_collapse_item_check {
      display: none;
      svg {
        display: none;
      }
    }
  }

  &.active {
    .effect_collapse_item_check {
      display: flex;
      svg {
        display: block !important;
      }
    }
    .effect_collapse_item_image {
      img {
        // border: 6px solid transparent;
        margin: 4px;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
      }
    }
    .effect_collapse_item_check_wrapper {
      display: flex;
    }
  }

  &_check {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.24);
    justify-content: flex-end;
    align-content: center;
    align-items: flex-end;
    border: 1px solid #2c7dfa;
    &_wrapper {
      width: 22px;
      height: 22px;
      border-radius: 26px 0px 0px 0px;
      background: #2c7dfa;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
      position: relative;
      svg {
        position: absolute;
        right: 2px;
        bottom: 5px;
      }
    }
  }

  &_title {
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    line-height: 16px;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    margin-top: 2px;
    margin-bottom: 6px;
    display: block;
    text-align: center;
    word-break: break-word;
  }

  &_new {
    position: absolute;
    // width: 29px;
    height: 14px;
    top: -7px;
    right: 0px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    svg + svg {
      margin-left: 4px;
    }
  }
}
