.tip_card {
  width: 300px;
  border-radius: 6px;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

  .video_wrapper {
    aspect-ratio: 268 / 178;
    width: 100%;
    
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 1px;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #1E1F22;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 16px;
  }

  .desc {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #414751;
    margin-top: 8px;
  }

  .button_row {
    display: flow-root;
    margin-top: 16px;

    .got_it_button {
      float: right;
      border-radius: 4px;
      // background: #2643FF;
      padding: 4px 22.5px;
      font-size: 13px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0px;
      color: #FFFFFF;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      outline: none;
      border:transparent;
      cursor: pointer;
      height: unset;
    }
  }
}