.wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  justify-content: space-around;
  background: rgba(22, 23, 25, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  align-content: center;
  align-items: center;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 32px);
    height: calc(100% - 57px);
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 16px;
    margin-top: 35px;
    position: relative;

    .btn_close {
      position: absolute;
      top: -32px;
      right: -8px;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      color: #fff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .icon {
        width: 12px;
        height: 12px;
        background-color: #fff;
        mask: url(../../../src/assets/img/dialog_open_app/btn_close_icon.svg)
          no-repeat center;
      }
    }

    .img_wrap {
      width: 100%;
      height: 20px;
      flex: 1;
      .img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .info {
      width: 100%;
      padding-top: 24px;
      .title {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        line-height: 28px;
        text-align: center;
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        color: hsla(0, 0%, 100%, 0.8);
        line-height: 22px;
        text-align: center;
        margin-top: 12px;
      }
      .bottom {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;

        button {
          color: #fff;
          height: 2.5rem;
          padding: 0.5rem;
          border-radius: 4px;
          border: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
        .btn_cancel {
          height: 42px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          border-radius: 8px 8px 8px 8px;
          width: 122px;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          margin-right: 16px;
          color: #fff;
          background: hsla(0, 0%, 100%, 0.08);
        }
        .btn_open_app {
          height: 42px;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          border-radius: 8px 8px 8px 8px;
          flex: 1;
          background: linear-gradient(50deg, #e6fa72, #62ff93);
          color: #2b2b2b;
        }
      }
    }
  }
}
