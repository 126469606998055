$bar_height: 12px;
$radius: 8px;
$bg: #3d3e40;
@keyframes progress-flash {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.wrapper {
  width: 100%;
  position: relative;
  height: $bar_height;
  .bg {
    height: $bar_height;
    width: 100%;
    background: $bg;
    border-radius: $radius;
  }
  .bar {
    width: 100%;
    height: $bar_height;
    transition: width 0.3s ease-in-out;
    background: linear-gradient(90deg, #26ffa5 0%, #2643ff 100%);
    // background: linear-gradient(90deg, #A3FFF6 0%, #265DEB 100%);
    border-radius: $radius;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 270px;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 17%,
        rgba(255, 255, 255, 0.8) 51%,
        rgba(255, 255, 255, 0) 84%
      );
      animation-name: progress-flash;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      animation-timing-function: linear;
    }
  }
}
