.canvas_extra_remove_btn {
  background: linear-gradient(270deg, #ff7221 0%, #ffb84e 100%);
  border-radius: 45px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  line-height: 12px;
  border: none;
  height: 40px;
  padding: 0 16px;
  position: relative;

  svg,
  polygon {
    fill: #ffffff !important;
    vertical-align: middle;
    margin-right: 8px;
  }

  &:hover {
    background: linear-gradient(90deg, #ff8e3e 0%, #ffce02 100%);
  }
  &:active {
    background: linear-gradient(90deg, #ff8e3e 0%, #ffce02 100%);
    svg:not(.no_icon) {
      path {
        fill: #ffffff !important;
      }
    }
  }

  &.text {
    background: transparent;
    color: #ff6812;
    padding: 0px;
    box-shadow: none !important;
    text-decoration: underline;

    svg,
    polygon {
      fill: #ffffff !important;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
  &_close {
    position: absolute;
    width: 32px;
    height: 32px;
    left: -16px;
    top: -16px;
  }
}
