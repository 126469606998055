.btn {
  width: 7.5rem;
  height: 2rem;
  background: #2c7dfa;
  border-radius: 0.25rem;
  font-size: 0.81rem;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border: none;
  outline: none;
}
