.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 9999;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.302);
  backdrop-filter: blur(20px);
  z-index: 9999;
}

.body {
  width: 536px;
  min-height: 168px;
  border-radius: 6px;
  padding: 24px;
  background: #fff;
  z-index: 10000;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    padding: 16px;
    width: calc(100% - 32px);
    height: auto ;
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.header_right {
  margin-left: 8px;
}

.title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;

  /* 文本色/🌚深色-1级 */
  color: #414751;
}
.content {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;

  font-variation-settings: "opsz" auto;
  /* 文本色/🌚深色-2级 */
  color: #6f757f;
  margin-top: 8px;
}
.foot {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  margin-top: 24px;
}

.btn {
  height: 40px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  box-sizing: border-box;
  /* 中性色/🌞分割线 */
  /* 样式描述：用于浅色背景下的分割线颜色 */
  border: 1px solid #dfe5eb;
  outline: none;

  box-shadow: none;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0px;

  /* 文本色/🌚深色-1级 */
  color: #414751;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  &:active {
    background: #eaebec;
    color: #9da2ad;
  }
}

.primary {
  height: 40px;
  border-radius: 4px;
  opacity: 1;

  /* 自动布局 */
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  outline: none;
  box-shadow: none;
  /* 主色/🌞-蓝色 */
  /* 样式描述：用于功能的强调色，以及主要操作或者按钮的强调色，还有部分层级比较高的icon高亮颜色 */
  background: #2643ff;
  border: none;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0px;

  /* 文本色/🌞浅色-1级 */
  color: #ffffff;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:active {
    background: #5477f0;
    color: rgba(255, 255, 255, 0.5);
  }
  cursor: pointer;
}
.close {
  width: 32px;
  height: 32px;
  opacity: 0.3;
  border-radius: 16px;
  background: #1a1b1c;
  position: absolute;
  right: -48px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  svg {
    width: 12px;
    height: 12px;
    color: #fff;
    path {
      fill: #fff;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
