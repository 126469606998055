.download_loading_mobile {
  width: 350px !important;

  .download_loading_process {
    // width: 100% !important;
  }
}

.download_loading {
  width: 584px;
  background: #ffffff;
  box-shadow: 0px 8px 16px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px 6px 6px 6px;
  padding: 0px;
  position: relative;
  z-index: 1040;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  align-content: center;
  &_header {
    height: 298px;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    // @media screen and (max-width: 750px) {
    //   height: unset;
    // }
  }
  &_content {
    height: 190px;
    width: 100%;
    background: #ffffff;
    padding: 24px 40px;
    // border-bottom-left-radius: 6px;
    // border-bottom-right-radius: 6px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 750px) {
      padding: 24px;
      min-height: 104px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &.loading-current {
      background: rgba(39, 40, 44, 0.9);
      backdrop-filter: blur(10px);
      padding: 0px 40px 24px 40px;
      .download_loading_text {
        color: #fff;
      }
    }
  }

  &_text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;

    /* 文本色/深色1级 */
    color: #2b2b2b;
    margin-bottom: 16px;
    @media screen and (max-width: 750px) {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
    }
  }
  &_process_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
  &_process {
    flex: 1;
    .ant-progress-inner {
      height: 8px !important;
      background: #f4f5f6;
      .ant-progress-bg {
        height: 8px !important;
        background: linear-gradient(90deg, #26ffa5 0%, #2643ff 100%);
      }
    }
  }
  &_process_text {
    min-width: 40px;
    flex-shrink: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;

    font-variation-settings: "opsz" auto;
    /* 文本色/🌞浅色-1级 */
    color: #ffffff;
  }
  &_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;

    /* 文本色/深色1级 */
    color: #2b2b2b;
  }
  &_desc {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    height: 44px;
    margin-top: 16px;
    flex: auto;
    .download_again {
      color: #2643ff;
      cursor: pointer;
    }
  }
  &_btn {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
    button {
      height: 40px;
      border-radius: 8px;
      padding: 0px 16px;
    }
  }
  &_close {
    position: absolute;
    right: -44px;
    top: 0px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
    svg {
      width: 12px;
      height: 12px;
      fill: #fff;
    }
  }
}
