.header {
  flex-shrink: 0;
  width: 100%;
  height: 56px;
  background: #1d1e21;
  border-radius: 0px 0px 0px 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  line-height: 10px;

  &_left {
    margin-left: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    > svg {
      height: 28px;
      fill: #ffffff;
      margin-right: 24px;
      @media screen and (max-width: 750px) {
        margin-right: 16px;
        width: 86px;
      }
    }
  }

  &_right {
    margin-right: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &_item {
      margin-left: 12px;
    }
    &_user {
      width: 200px;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      border-bottom: 1px solid #f4f5f6;
      &_img {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        flex-shrink: 0;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          margin: 0;
          cursor: auto;
        }
      }
      &_info {
        margin-left: 8px;
        flex: 1;
        span {
          display: block;
        }
        &_name {
          font-size: 13px;
          max-width: 160px;
          font-weight: 600;
          color: #414751;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &_email {
          font-size: 12px;
          font-weight: 400;
          max-width: 160px;
          color: #9da2ad;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &_logout {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 40px;
    }

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
    &_slider {
      margin: 0 4px;
    }
    &_signin,
    &_signup {
      cursor: pointer;
    }

    &_dropdown {
      top: 60px !important;
      border-radius: 4px;
    }
  }
}

.link_to_aicg {
  cursor: pointer;
  width: 132px;
  height: 40px;
  border-radius: 20px;
  padding: 0 16px;
  background: linear-gradient(90deg, #26ffa5 0%, #2643ff 100%);
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  &:hover {
    background: linear-gradient(90deg, #4dffb5 0%, #4d64ff 100%);
  }
  svg {
    margin-right: 8px;
    vertical-align: middle;
    fill: #ffffff;
  }
  @media screen and (max-width: 750px) {
    padding: 0 8px;
    display: none;

    svg {
      margin-right: 0px;
    }
  }
  &_text {
    white-space: nowrap;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;

    color: #ffffff;
    @media screen and (max-width: 750px) {
      display: none;
      padding: 0;
    }
  }
}
