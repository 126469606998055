.container {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .inner {
    padding: 24px;
    border-radius: 6px;
    width: 536px;
    background: #ffffff;
    position: relative;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    .close {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      position: absolute;
      left: calc(100% + 6px);
      top: 0;
      background: #1a1b1c;
      &:hover {
        background: rgba(0, 0, 0, 0.4);
      }

      svg {
        fill: #ffffff;
      }
    }
    .time-out {
      display: flex;
    }
    .title {
      color: #414751;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    .des {
      font-size: 14px;
      font-weight: 500;
      color: #6f757f;
      margin-top: 8px;
    }
    .title-fra {
      margin-left: 8px;
    }
    .button-fra {
      margin-top: 24px;
      height: 40px;
      display: flex;

      justify-content: flex-end;
      & > div {
        cursor: pointer;
        height: 100%;
        padding: 0 16px;
        border-radius: 4px;
        line-height: 40px;
        &:nth-child(1) {
          border: 1px solid #dfe5eb;
        }
        &:nth-child(2) {
          margin-left: 8px;
          background: #2643ff;
          color: #ffffff;
          &:hover {
            background: #223ce5;
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .container {
    .inner {
      padding: 16px;
      max-width: 90%;
      .close {
        display: none;
      }
      .time-out {
        flex-direction: column;
        align-items: center;
        .title {
          text-align: center;
          margin-top: 12px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        .des {
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          line-height: 19px;
        }
      }
      .button-fra {
        & > div {
          font-size: 13px;
          font-weight: 500;
          text-align: center;
          flex: 1;
        }
      }
    }
  }
}
