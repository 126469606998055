.download {
  .main_download {
    min-width: 120px;
    height: 36px;
    padding: 0 16px;
    // background: #2643ff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;
      fill: #ffffff;
      margin-right: 6px;
      vertical-align: middle;
    }

    &_text {
      @media screen and (max-width: 750px) {
        display: none;
      }
    }

    &.disabled {
      background: #5477f0;
    }

    @media screen and (max-width: 750px) {
      min-width: unset;
      width: 32px;
      height: 32px;
      border-radius: 40px;
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 0px;
      background: #4285f4;
      box-shadow: 0px 2px 4px 0px rgba(66, 133, 244, 0.1);
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 66px;
        height: 48px;
        left: -17px;
        top: -8px;
      }

      svg {
        margin-right: 0px;
      }
    }
  }
}

.download_modal_wrapper {
  padding: 0px;

  :global {
    .ant-popover-inner {
      border-radius: 6px;
    }

    .ant-popover-inner-content {
      padding: 0px;
    }
  }

  .download_modal {
    padding: 0px;

    &_title {
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      color: #2b2b2b;

      &_close {
        width: 24px;
        height: 24px;
        border-radius: 60px;
        opacity: 1;

        background: rgba(26, 27, 28, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          fill: #9da2ad;
        }

        &:hover {
          background: rgba(26, 27, 28, 0.2);
        }

        @media screen and (max-width: 750px) {
          position: absolute;
          left: 8px;
          top: 8px;
          background: transparent;

          svg {
            fill: #000000;
          }
        }
      }

      @media screen and (max-width: 750px) {
        justify-content: center;
      }
    }

    &_content {
      width: 288px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      padding: 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 4px 0px rgba(65, 71, 81, 0.1);
      padding: 16px;

      @media screen and (max-width: 750px) {
        width: 100%;
        border-radius: 0px;
      }
    }

    &_item {
      margin-top: 16px;

      &_btn {
        position: relative;
      }

      button {
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        // align-items: center;
        // padding: 12px 8px;
        // align-self: stretch;
        // background: #f4f5f6;
        // color: #2b2b2b;
        // box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        border-radius: 40px;
        cursor: pointer;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        box-shadow: unset;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          vertical-align: middle;
          fill: #2b2b2b;
        }

        @media screen and (max-width: 750px) {
          height: 54px;
        }
      }

      button.primary {
        // background: #221ff6;
        // border: 1px solid rgba(0, 0, 0, 0.1);
        // color: #fff;
        svg {
          fill: #fff;
        }
      }

      &_desc {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0px;

        /* 文本色/浅色3级 */
        color: #9da2ad;

        margin-top: 8px;
      }

      &_tips {
        height: 20px;
        border-radius: 14px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;
        background: linear-gradient(270deg, #ff6812 0%, #ffc01c 100%);
        border-radius: 14px;
        position: absolute;
        right: 8px;
        top: -10px;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0px;
        color: #ffffff;

        span.tips_icon {
          display: inline-block;
          width: 12px;
          margin-right: 2px;
        }

        svg {
          width: 12px !important;
          height: 12px !important;
        }
      }
    }
  }
}

.download_modal_wrapper.mobile {
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  background: rgba(43, 43, 43, 0.6);
  transform-origin: 50vw 100vh !important;
  position: fixed;
  transition: none;

  :global {
    .ant-popover-inner {
      border-radius: 0px;
    }

    .ant-popover-content {
      position: fixed;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
    }
  }
}
