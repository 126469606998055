@import "src/styles/variables.scss";

.toolleft {
  position: relative;
  width: $width_tool_left;
  height: 100%;
  flex-shrink: 0;
  background: #2b2c2f;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.07999999821186066);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  > div {
    width: 100%;
  }
  &_effect_list {
    flex: 1;
  }

  // &_mask {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: transparent;
  //   cursor: pointer;
  //   z-index: 1000;
  // }

  @media screen and (max-width: 1280px) {
    width: $width_tool_left_small;
    
  }
}
