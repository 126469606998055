.noimage {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &_bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &_mask {
      background: linear-gradient(180deg, rgba(36, 37, 39, 0) 0%, #242527 50%);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &_content {
    max-width: 900px;
    width: calc(100% - 128px);
    // margin: 0px 64px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    z-index: 200;
    max-height: 100%;
    &_header {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 300px;
      &_icon {
        height: 48px;
        width: 48px;
        margin-left: 6px;
        svg {
          width: 100%;
          height: 100%;
        }
        @media screen and (max-width: 1280px) {
          height: 32px;
          width: 32px;
        }
        @media screen and (max-height: 650px) {
          height: 32px;
          width: 32px;
        }
      }
      &_content {
        font-family: Poppins;
        font-size: 32px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        color: #fff;
        text-align: center;
        span {
          background: linear-gradient(90deg, #ffffff 0%, #acb9ff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        @media screen and (max-width: 1280px) {
          font-size: 24px;
        }
        @media screen and (max-height: 650px) {
          font-size: 24px;
        }
      }
    }
    &_drag_panel {
      background: #ffffff;
      width: 100%;
      min-width: 300px;
      border-radius: 12px;
      margin: 0px auto;
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 48px 72px;
      background: rgba(39, 40, 44, 0.4);
      border: 2px dashed #9da2ad;
      backdrop-filter: blur(10px);
      min-height: 180px;
      max-height: 240px;
      &:hover {
        background: rgba(38, 67, 255, 0.1);
        cursor: pointer;
      }
      .noimage_btn {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      @media screen and (max-height: 650px) {
        margin-top: 8px;
        padding: 32px 72px;
      }
      @media screen and (max-width: 1280px) {
        // margin: 0px 32px;
        padding: 24px 24px;
      }
    }
    &_sample_wrapper {
      width: 100%;
    }

    @media screen and (max-width: 1280px) {
      // margin: 0px 32px;
      width: calc(100% - 220px);
    }
    @media screen and (max-width: 960px) {
      // margin: 0px 32px;
      width: calc(100% - 112px);
    }
  }

  .noimage_btn_new {
    background: #221ff6;
  }

  &_icon {
    width: 63px;
    height: 48px;
    margin: 0 auto;
    margin-top: 69px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &_text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.39px;

    color: #ffffff;
  }

  &_btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    padding: 0 24px;
    margin-top: 32px;
    height: 56px;
    border-radius: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 32px;
    background: #2643ff;
    border-color: transparent;
    @media screen and (max-width: 1280px) {
      margin-top: 24px;
    }
  }

  &_right_bottom {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 200;
  }
}

@keyframes scaleBtn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
