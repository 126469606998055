.search_list {
  padding-top: 8px;
  &_loading {
    margin-top: 24px;
    display: flex;
    justify-content: space-around;
    img {
      width: 36px;
      height: 36px;
      animation: App-logo-spin infinite 1s linear;
    }
  }
  > div {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    > div {
      width: calc(33.3% - 6px);
      margin-right: 12px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &_numbers {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 8px;
  }

  &_no_list {
    display: flex;
    flex-direction: column;
    justify-content: flex-tart;
    align-items: center;
    align-content: center;

    img {
      width: 140px;
      margin-top: 24px;
    }

    span {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.4);
      line-height: 16px;
    }
  }
}
