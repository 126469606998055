.effect-collapse-fra {
  display: flex;
  color: #bec0c8;
  .see-all {
    margin-left: auto;
    display: flex;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    cursor: pointer;
    &:hover {
      svg {
        fill: #ffffff;
        path {
          fill: #ffffff !important;
        }
      }
      color: #ffffff;
    }
    svg {
      margin-left: 6px;
      transform: rotate(90deg);
      fill: #bec0c8;
      path {
        fill: #bec0c8 !important;
      }
    }
  }
}
