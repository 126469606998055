$btn_primary_color:#221FF6;
$btn_primary_hover_color:#003ECB;
$btn_primary_active_color:#003ECB;

$btn_default_bg_color:#F0F1F5;
$btn_default_border_radius:6px;

//#region 背景
$bg_header: #1E1F22;
$bg_left:#2B2C2F;
$bg_content_help_panel: #2F3034;
$bg_content:#242527;
//#endregion

$width_tool_left: 368px;
$width_tool_left_small: 340px;



