.search_tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  &_title {
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: 0px;
    color: #9da2ad;
    margin-bottom: 8px;
  }
  &_content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &_item {
    height: 36px;
    padding: 10px 8px;
    background: #333437;
    border-radius: 6px;
    font-size: 13px;
    font-weight: normal;
    color: #ffffff;
    line-height: 16px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border: 1px solid #444449;
    position: relative;

    &:hover {
      background: rgba(216, 216, 216, 0.2);
    }

    &.active {
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0);
      background: transparent;
      border: 2px solid #2c7dfa;
    }
    &_new {
      width: 8px;
      height: 8px;
      background: #ff306c;
      border-radius: 50%;
      position: absolute;
      top: -4px;
      right: 0px;
    }
  }

  &_more {
    width: 36px;
    height: 36px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px 8px 8px 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    cursor: pointer;

    svg {
      fill: #ffffff;
      transform: rotate(-90deg);
    }

    &.expend {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
.toolleft_search_input + .search_tags_down {
  margin-top: 8px;
}
.search_tags_down {
  position: relative;
  .search_controller {
    position: absolute;
    top: 0px;
    width: 32px;
    height: 36px;
    opacity: 1;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    user-select: none;

    svg {
      width: 16px;
      height: 16px;
    }

    &.search_controller_disabled {
      display: none;
    }
    &.serarch_next {
      background: linear-gradient(90deg, rgba(43, 44, 47, 0) 1%, #2b2c2f 100%);
    }
    &.serarch_prev {
      background: linear-gradient(90deg, rgba(43, 44, 47, 0) 1%, #2b2c2f 100%);
    }
    &_icon {
      width: 24px;
      height: 24px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      background: #333437;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .serarch_prev {
    transform: rotate(180deg);
    left: 0px;
    margin-top: 8px;
  }
  .serarch_next {
    right: 0px;
    margin-top: 8px;
  }
  &_scroll {
    overflow-x: auto;
    overflow-y: visible;
    padding-top: 8px;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    &::-webkit-scrollbar {
      // width: 0px;
      height: 0px;
    }
  }
  .search_tags_item {
    height: 36px;
    padding: 10px 8px;
    background: #333437;
    border-radius: 6px;
    font-size: 13px;
    font-weight: normal;
    color: #ffffff;
    line-height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border: 1px solid #444449;
    user-select: none;
    margin-right: 0px;
    margin-bottom: 0px;
    &:hover {
      background: #545459;
      border: 1px solid #54545b;
    }

    &.active {
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0);
      background: transparent;
      border: 1px solid #2c7dfa;
    }

    & + .search_tags_item {
      margin-left: 4px;
    }

    &:last-child {
      margin-right: 4px;
    }
  }
  .search_tags_divider {
    width: 1px;
    height: 18px;
    margin: 0 4px;
    background: #3c3d3f;
    flex-shrink: 0;
  }
}
.tooltip_help.ant-tooltip {
  max-width: unset;
}
.tooltip_help.ant-tooltip.ant-tooltip-placement-left {
  max-width: max-content;
  z-index: 1100;
  .ant-tooltip-inner {
    padding: 0px;
  }
}
.tooltip_help.ant-tooltip.ant-tooltip-placement-leftTop {
  max-width: max-content;
  z-index: 1100;
  .ant-tooltip-inner {
    padding: 0px;
  }
}

.tooltip_help {
  .ant-tooltip-inner {
    padding: 0;
  }
  .ant-tooltip-arrow {
    top: 20px !important;
    display: none;
  }
}
