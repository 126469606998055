.link_to_aigc {
  width: 100%;
  height: 150px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 8px;

  &_background {
    width: 100%;
    height: 100%;
    position: relative;

    span {
      position: absolute;
      bottom: 4px;
      left: 50%;
      transform: translateX(-50%);
      height: 22px;
      background: rgba(42, 43, 47, 0.3);
      border-radius: 4px 4px 4px 4px;
      padding: 3px 10px;
      font-size: 13px;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;

      white-space: nowrap;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    .link_to_aigc_content {
      opacity: 1;
      backdrop-filter: blur(10px);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    }
    .link_to_aigc_background {
      span {
        display: none;
      }
    }
  }
  &_content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.2s linear;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      flex-direction: column;

      > div {
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        flex-direction: column;
        gap: 16px;
      }

      span {
        height: 22px;
        padding: 3px 10px;
        line-height: 20px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        color: #ffffff;
      }
    }
  }

  &_btn {
    width: 122px;
    height: 40px;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 24px;
    gap: 8px;
    background: linear-gradient(90deg, #26ffa5 0%, #2643ff 100%);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
    &:hover {
      background: linear-gradient(90deg, #4dffb5 0%, #4d64ff 100%);
    }
    svg {
      color: #ffffff;
    }
  }
}
