/*
 * @Author: xiaosihan
 * @Date: 2021-03-23 17:20:32
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2021-03-23 17:21:56
 */
 @keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.imageLoading {
  background: linear-gradient(90deg, #333438 25%, #3b3c41 37%, #333438 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1s ease infinite;

  &.isLight {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1s ease infinite;
  }

  &.isBanerLight {
    background: linear-gradient(90deg, #9da2ad 25%, #dadbdc 37%, #9da2ad 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1s ease infinite;
  }
}

@for $i from 1 to 9 {
  .imageLoadingDelay#{$i} {
    animation-delay: (#{$i / 10}s) !important;
  }
}
