.modalBG {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.4);
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-family: Poppins-Regular, Poppins;

  &__close {
    position: absolute;
    top: 0px;
    right: -38px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0;
    background: rgba(#1a1b1c, 0.3);
    cursor: pointer;

    > svg {
      width: 16px;
      height: 16px;
      fill: #fff;
    }

    &:hover {
      background: rgba(#1a1b1c, 0.5);
      // transform: rotate(90deg);
    }
    &:active {
      > svg {
        fill: rgba(225, 225, 225, 0.5);
      }
    }
  }

  &__info {
    width: 880px;
    height: 560px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    color: #414751;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    * {
      box-sizing: border-box;
    }
  }

  .dailog_left {
    width: 400px;
    padding: 0 40px;
    &_title {
      display: block;
      font-size: 22px;
      font-weight: 600;
      color: #1b1e24;
      line-height: 28px;
      margin-bottom: 8px;
      span {
        display: inline-block;
        background: linear-gradient(270deg, #ff8b2b 0%, #ffc852 100%);
        color: #ffffff;
        border-radius: 4px;
        padding: 0 4px;
        margin: 0 4px;
      }
    }
    &_subtitle {
      display: block;
      font-size: 12px;
      margin-bottom: 24px;
    }
    .desc_item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 16px;
      font-size: 12px;
      color: #1b1e24;
      line-height: 18px;
      &:last-of-type {
        margin-bottom: 24px;
      }
      .icon_svg {
        margin-right: 8px;
        margin-top: 4px;
        width: 14px;
        height: 14px;
      }
      b {
        font-weight: 500;
      }
    }
    .dailog_tips {
      display: block;
      font-size: 12px;
      color: #0fb497;
      line-height: 16px;
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 16px;
      }
    }
    button {
      width: 100%;
    }

    .dailog_btn {
      background: linear-gradient(315deg, #edac78 0%, #fbcdad 100%);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #3c1c07;
      line-height: 16px;

      &:hover {
        background: linear-gradient(315deg, #f8be8e 0%, #fbd4ba 100%);
      }
    }
  }
  .dailog_right {
    width: 480px;
    height: 100%;
    display: flex;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0 6px 6px 0;
    }
  }
}

// discount
.discount {
  color: #ffffff;
  background: #252930;
  background-size: cover;
  .dailog_left {
    padding: 0 56px;
    &_title {
      display: block;
      color: inherit;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      img {
        height: 24px;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: 8px;
      }
    }
    &_subtitle {
      // font-weight: 600;
      // display: block;
      // font-size: 24px;
      // line-height: 32px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    .desc_item {
      color: inherit;
      font-size: 14px;
      line-height: 18px;
      span {
        color: rgba(255, 255, 255, 0.6);
      }
      b {
        color: #fff;
      }
    }
    button {
      background: linear-gradient(315deg, #edac78 0%, #fbcdad 100%);
      margin-top: 16px;
      font-size: 14px;
      font-weight: 500;
      color: #3c1c07;
      line-height: 16px;
      border-radius: 4px;

      &:hover {
        background: linear-gradient(315deg, #f8be8e 0%, #fbd4ba 100%);
      }
    }
  }
}

.free_discount {
  .dailog_left {
    &_title {
      font-size: 22px;
      color: #1b1e24;
      line-height: 28px;
    }

    &_subtitle {
      font-size: 12px;
      font-weight: 400;
      color: #1b1e24;
      line-height: 18px;
    }
  }
  .desc_item {
    font-size: 12px;
    font-weight: 500;
    color: #1b1e24;
    line-height: 18px;

    b {
      font-weight: 600 !important;
    }
  }
}

.new_feature {
  .dailog_left {
    &_title {
      display: block;
      font-weight: 600;
      color: #1a1b1c;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;

      img {
        height: 24px;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: 8px;
      }
    }
    .desc_item {
      margin-bottom: 16px;
      font-size: 14px;
      color: #1b1e24;
      line-height: 18px;
      &:last-of-type {
        margin-bottom: 24px;
      }
    }
    .dailog_tips {
      display: block;
      font-size: 14px;
      font-weight: 500;
      color: #1b1e24;
      line-height: 22px;
      margin-bottom: 24px;
    }
    button {
      width: 100%;
      &.upgrade_btn {
        margin-bottom: 16px;
      }
    }
  }

  .dailog_right {
    width: 480px;
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(90deg, #8e67ff 0%, #977bff 100%);
    .right_img {
      width: 440px;
      height: 330px;
      border-radius: 4px;
    }
  }
}
