.sample_list_wrapper {
  margin-top: 32px;
  .sample_list_header {
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    &_title {
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0em;
      color: #9da2ad;
    }
    &_pagination {
      padding: 0px 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      height: 36px;
      border-radius: 6px;
      font-family: Poppins;
      font-size: 13px;
      font-weight: normal;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.39px;

      font-variation-settings: "opsz" auto;
      /* 中性色/浅色-辅助色 */
      /* 样式描述：用于浅色界面的滚动条颜色 */
      color: #9da2ad;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        svg,path{
          fill: #ffffff;
        }
        div{
          color: #ffffff;
        
        }
      }
      div {
        margin-left: 8px;
        font-family: Poppins;
        font-size: 13px;
        font-weight: normal;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.39px;

        font-variation-settings: "opsz" auto;
        /* 中性色/浅色-辅助色 */
        /* 样式描述：用于浅色界面的滚动条颜色 */
        color: #9da2ad;
      }
    }
  }
  .sample_list_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .sample_list_item {
      flex: 1;

      &_img {
        aspect-ratio: 290 / 217;
        border-radius: 12px;
        overflow: hidden;
        position: relative;
      }
      &_desc {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 8px;
        height: 36px;
        background: linear-gradient(180deg, rgba(43, 43, 43, 0) 0%, #2b2b2b 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.19px;
        color: #ffffff;
      }
      & + .sample_list_item {
        margin-left: 16px;
      }
      &_try {
        // width: 92px;
        width: fit-content;
        height: 36px;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        margin-top: 8px;
        cursor: pointer;
        color: #ffffff;
        &_icon {
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.1);

          backdrop-filter: blur(10px);
        }
      }
    }

    :global{
      .loading-skeleton-sample{
        &+.loading-skeleton-sample{
          margin-left: 16px;
        
        }
      }
    }
  }

  @media screen and (max-height: 650px) {
    margin-top: 8px;
    
  }
}
