.create_now {
  position: relative;
  width: 100%;
  height: 100%;
  &_background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &_effect {
      animation: opacity 24s linear infinite;
    }
  }

  &_center {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    align-content: center;

    &_container {
      position: absolute;
      width: 100%;
      top: 35%;
      left: 0;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      align-content: center;
    }
    &_icon svg {
      width: 150px;
      height: 55px;
      fill: #ffffff;
    }

    &_text {
      margin-top: 64px;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      line-height: 18px;
      text-align: center;
    }

    &_btn {
      margin-top: 24px;
      .noimage_btn {
        width: 250px;
        height: 100px;
        background: transparent;
        box-shadow: 0px 4px 4px 1px rgba(44, 125, 250, 0.1);
        border-radius: 8px;
        // border: 2px solid #ffffff;
        border-color: transparent;
        box-shadow: none;
        padding: 10px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        align-content: center;

        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 10px;

        svg,
        path {
          fill: #ffffff;
          width: 24px;
          height: 24px;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }

        &:active {
          background-color: rgba(0, 0, 0, 0.5);
          color: rgba(255, 255, 255, 0.7) !important;

          svg,
          path {
            fill: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }

  &_center_mobile {
    .create_now_center {
      &_icon svg {
        width: 105px;
        height: 38px;
      }
      &_text {
        margin-top: 32px;
        font-size: 13px;
        font-weight: 600;
        color: #ffffff;
        line-height: 18px;
      }
      &_btn {
        margin-top: 12px;
        .noimage_btn {
          width: 175px;
          height: 70px;
          box-shadow: 0px 4px 4px 1px rgba(44, 125, 250, 0.1);
          border-radius: 8px;
          border: 2px solid #ffffff;

          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          line-height: 10px;

          svg,
          path {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
