.download_mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;

  &_close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      fill: rgba(0, 0, 0, 0.4);
    }
  }

  &_img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    padding: 0 20px;
    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 4px;
    }
  }
}
