@keyframes fadeup {
  0% {
    opacity: 0;
    transform: translate(0, -5px);
  }
  70% {
    opacity: 1;
    transform: translate(0, -15px);
  }
  99% {
    opacity: 0;
    transform: translate(0, -15px);
  }
  100% {
    opacity: 0;
    transform: translate(0, -15px);
    display: none;
  }
}
.collection {
  position: absolute;
  left: 4px;
  bottom: 4px;
  width: 24px;
  height: 24px;
  background: rgba(43, 43, 43, 0.8);
  border-radius: 4px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 3;
  .star-fra {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .collection-animation {
    animation: fadeup 1s ease-out forwards;
    position: absolute;
    bottom: 100%;
    left: 0;
    border-radius: 4px;
    padding: 4px;
    background: #414751;
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    white-space: nowrap;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    svg {
      margin-right: 2px;
    }
  }
  &.collection-active {
    left: 8px;
    bottom: 8px;
  }
}

.regenerate {
  z-index: 3;
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 24px;
  height: 24px;
  background: rgba(43, 43, 43, 0.8);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
}

.effect-seat {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    &:nth-child(2) {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #bec0c8;
      text-align: center;
    }
    &:nth-child(3) {
      margin-top: 4px;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      color: #9da2ad;
      text-align: center;
    }
  }
}

.tag {
  z-index: 5;
  position: absolute;
  border-radius: 7px;
  height: 14px;
  font-size: 9px;
  font-weight: 600;
  color: #ffffff;
  background: #db2358;
  padding: 0 4px;
  right: 0;
  top: 0;
  transform: translate(0%, -50%);
  &.hot {
    background: #ed6409;
  }
}

.title {
  position: relative;
  .red-tag {
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #ff306c;
  }
}

.tooltips-styles {
  font-size: 12px;
  :global {
    .ant-tooltip-inner {
      background: #c0d7f9;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;

      color: #262b33;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    }
    .ant-tooltip-arrow-content {
      background: #c0d7f9;
    }
  }
}

.more {
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  z-index: 3;
  background: rgba(43, 43, 43, 0.5);

  backdrop-filter: blur(10px);
  &.more-active {
    right: 8px;
    bottom: 8px;
  }
}

@keyframes Fade {
  from {
    transform: translate(0, 100%);
  }
  to {
    transform: translate(0, 0);
  }
}
.styles-info-fra {
  position: fixed;

  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  .styles-inner {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
    background: #212127;
    padding: 16px 24px 24px 24px;
  }
  .styles-up {
    animation: Fade 0.2s ease-out forwards;
  }
  .styles-title {
    height: 40px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #9da2ad;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      position: absolute;
      width: 12px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      fill: #ffffff;
    }
  }
  .styles-content {
    padding: 16px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 6px;
    margin-top: 24px;
    p {
      color: #ffffff;
      &:nth-child(1) {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: #9da2ad;
      }
      &:nth-child(2) {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        margin-top: 4px;
      }
      &:nth-child(3) {
        margin-top: 16px;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: #9da2ad;
      }
      &:nth-child(4) {
        margin-top: 4px;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
      }
    }
  }
}

.scroll-styles {
  font-size: 12px;
  margin-top: 16px;
}
