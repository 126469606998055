.effect_collapse {
  &_title {
    height: 42px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px 8px 8px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 0 16px;
    margin-bottom: 8px;
    cursor: pointer;

    font-size: 13px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
    line-height: 15px;

    svg {
      vertical-align: middle;
      fill: rgba(255, 255, 255, 0.8);
      transform: rotate(-90deg);
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &_collapse {
      svg {
        transform: rotate(0deg);
      }
    }
  }

  &_title1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    // margin-top: 16px;
    // margin-bottom: 6px;
    // cursor: pointer;

    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz" auto;
    color: #9da2ad;
  }

  &_list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 12px;

    > div {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      > div {
        width: calc(33.3% - 6px);
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  & + & {
    margin-top: 12px;
  }
}
