.image_compare_sample {
  width: 100%;
  position: relative;
  height: 100%;
  border-radius: 6px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .compare_cutout, .compare_original {
    position: absolute;;
    top: 0;
    left: 0;
    height: 100%;
  }

  .compare_original {
    width: 100%;
    height: 100%;
    overflow: hidden;
   
    &.compare_original_border{
      border-right: 1px solid #FFFFFF;
    }
    img {
      height: 100%;
      border-radius: 6px;
      user-select: none;
    }
  }
  .compare_cutout {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    img {
      height: 100%;
      border-radius: 6px;
      user-select: none;
    }
  }

  .compare_icon {
    position: absolute;
    top: 0;
    left: calc(50% - 2px);
    width: 2px;
    height: 100%;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    user-select: none;

    &_drag {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: -12px;
      border-radius: 50%;
      transform:  translateY(-50%);
      background: #FFFFFF;
    }
  }

  .compare_loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F4F5F6;
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1s ease infinite;
    aspect-ratio: 290 / 217 ;
    img {
      width: 32px;
      height: 32px;
      animation: rotate2 1s linear infinite;
    }
  }
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.imageLoading {
  background: linear-gradient(90deg, #333438 25%, #3b3c41 37%, #333438 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1s ease infinite;

  &.isLight {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1s ease infinite;
  }
}